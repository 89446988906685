var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "drugUseInstructionWrap",
        staticStyle: { padding: "1rem" },
      },
      [
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("药品名称"),
        ]),
        _c("div", { staticClass: "duiContent" }, [_vm._v(" 阿昔替尼片 ")]),
        _c("div", { staticClass: "duiTitle" }, [_c("span"), _vm._v("适应症")]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(
            " 用于既往接受过一种酪氨酸激酶抑制剂或细胞因子治疗失败的进展期肾细胞癌（RCC）的成人患者。 "
          ),
        ]),
        _c("div", { staticClass: "duiTitle" }, [_c("span"), _vm._v("禁忌症")]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(" 使用本药物出现过敏则不可使用。 "),
        ]),
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("用药安全"),
        ]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(
            " 孕妇服药时，可能对胎儿造成伤害。建议哺乳期女性在接受治疗和末次给药后2周内停止哺乳。有育龄女性伴侣的男性在接受治疗期间和末次给药后1周内采取有效避孕措施。 "
          ),
        ]),
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("使用方法"),
        ]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(
            " 阿昔替尼可与食物同服或在空腹条件下给药，每日两次给药的时间间隔约为12小时。应用一杯水送服阿昔替尼。 "
          ),
        ]),
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("漏服处理"),
        ]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(
            " 如果患者呕吐或漏服一次剂量，不应另外服用一次剂量。应按常规服用下一次处方剂量。 "
          ),
        ]),
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("不良反应及处理方法"),
        ]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(
            " 高血压：咨询医生进行高血压药物治疗。如使用高血压药物治疗后仍存在持续性高血压，仍需咨询医生。"
          ),
          _c("br"),
          _vm._v(" 动脉、静脉血栓栓塞：曾有这些事件病史的患者应慎用阿昔替尼。"),
          _c("br"),
          _vm._v(
            " 血红蛋白或血细胞比容升高：应在开始阿昔替尼治疗前并在治疗过程中定期监测血常规。"
          ),
          _c("br"),
          _vm._v(
            " 出血：出现包括脑出血、咯血、血尿、下消化道出血和黑便，应咨询医生是否暂停阿昔替尼给药。"
          ),
          _c("br"),
          _vm._v(
            " 心力衰竭：如出现气促、乏力、心悸、下肢水肿的情况，及时就医。"
          ),
          _c("br"),
          _vm._v(" 蛋白尿：定期监测尿常规。"),
          _c("br"),
          _vm._v(" 肝酶升高：治疗期间定期监测肝功能。 "),
        ]),
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("不能同服的药物和食物"),
        ]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(
            " 同时使用，酮康唑、伊曲康唑、克拉霉素、红霉素、阿扎那韦、茚地那韦、奈法唑酮、那非那韦、利托那韦、沙奎那韦、泰利霉素，利福平、地塞米松、苯妥英、卡马西平、利福布汀、利福喷汀、苯巴比妥及贯叶连翘、茶碱，需要咨询医生。 "
          ),
        ]),
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("饮食运动"),
        ]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(" 不建议同时食用葡萄柚。 "),
        ]),
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("注意事项"),
        ]),
        _c("div", { staticClass: "duiContent" }, [_vm._v(" 30℃以下保存。 ")]),
        _c("div", { staticClass: "duiTitle" }, [
          _c("span"),
          _vm._v("用法用量说明"),
        ]),
        _c("div", { staticClass: "duiContent" }, [
          _vm._v(
            " 根据患者安全性和耐受性的个体差异，使用剂量为2~10mg，每日两次，两次给药的时间间隔约为12小时。 "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }